@use '../abstracts/' as *;
.btn {
  background-color: $tertiary-blue;
  border-radius: 10px;
  color: white;
  padding: 6px;
  height: 2.5rem;
  width: 12rem;
  cursor: pointer;
  &:hover {
    background-color: $tertiary-blue-darker;
  }
  margin: 1rem;
  text-align: center;
  &__span {
    font-weight: bold;
  }
  @include sm {
    height: 2.6rem;
    width: 13rem;
    font-size: 1.1rem;
  }
  &--search {
    width: 8rem;
  }
}

.settings-btn,
.search-btn {
  width: 20px;
  height: 20px;
  &__icon {
    fill: $tertiary-blue;
    width: 20px;
    height: 20px;
  }
}

.back-btn,
.next-btn {
  &:disabled {
    &:hover {
      fill: gray;
    }
    fill: gray;
  }
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  svg {
    width: 3rem;
  }
  fill: $tertiary-blue;
  &:hover {
    fill: $tertiary-blue-darker;
  }
}

.logout-btn,
.organize-btn {
  margin-left: auto;
  display: none;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &__icon {
    fill: $tertiary-blue;
    width: 2rem;
  }
  &__text {
    color: $tertiary-blue;
    font-weight: bold;
  }
  &:hover &__icon {
    fill: $tertiary-blue-darker;
  }
  &:hover &__text {
    color: $tertiary-blue-darker;
  }
  @include sm {
    display: flex;
  }
}
