@use '../abstracts/' as *;
.search-page {
  display: flex;
  flex: 1;
  margin-top: 2rem;
  flex-direction: column;
  text-align: center;
  &__ctn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &__results {
    @include sm {
      padding: 0 0;
    }
    @include md {
      padding: 0 2%;
    }
    @include lg {
      padding: 0 10%;
    }

    @include xl {
      padding: 0 20%;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 6rem;
  &__text {
    font-size: 1.5rem;
    color: $tertiary-blue-darker;
    font-weight: bold;
  }
}
.question,
.answer {
  margin: 2rem auto;
  padding: 1.5rem;
  width: 90%;
  border-radius: 1rem;
  color: white;
  background-color: $tertiary-blue;
  display: flex;
  text-align: start;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
  @include sm {
    width: 80%;
  }
  &__title {
    color: white;
  }

  &__btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    svg {
      width: 2rem;
      fill: white;
    }
    align-self: flex-end;
    background-color: $tertiary-blue-darker;
    border-radius: 10px;
    height: 3rem;
    width: 8rem;
    margin-top: 1rem;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: $secondary-blue;
      color: $tertiary-blue-darker;
    }
    &:hover svg {
      fill: $tertiary-blue-darker;
    }
  }

  &__details {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    @include sm {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__vote-count,
  &__answer-count {
    background-color: $primary-black;
    padding: 0.5rem;
    border-radius: 5px;
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }
  &__tag {
    border-radius: 10px;
    padding: 7px;
    color: white;
    background-color: $primary-blue;
  }
}
.interaction-ctn {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
