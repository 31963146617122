@use '../abstracts/' as *;
.header {
  display: flex;
  height: 3rem;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 0 1rem;
  @include sm {
    padding: 0 2rem;
  }
  .logo-ctn {
    cursor: pointer;
    // flex: 1;
    // flex-basis: 25%;
    // flex-grow: 0;
    // flex-shrink: 0;
    // flex: 1;
    flex-basis: 25%;
    @include lg {
    }
  }
  &__icon {
    width: 2rem;
    @include lg {
      display: none;
    }
  }
  &__logo {
    display: none;
    cursor: pointer;
    @include lg {
      display: inline-block;
      width: 14rem;
    }
    @include xl {
      width: 15rem;
    }
  }
  &__search {
    flex-basis: 70%;
    @include lg {
      flex-basis: 50%;
    }
    display: none;
    @include sm {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__search--full {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  &__search-btn {
    flex-basis: 50%;
    fill: $tertiary-blue-darker;
    @include sm {
      display: none;
    }
  }
  &__nav {
    display: none;
    @include sm {
      display: flex;
    }
    margin-left: auto;
  }
  &__buttons {
    flex-basis: 25%;
    display: none;
    gap: 1rem;
    justify-content: space-around;
    @include sm {
      display: flex;
    }
  }
}

.hamburger-menu {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  flex-basis: 25%;
  // flex: 1;
  z-index: 1;
  @include sm {
    display: none;
  }
  .hamburger-react {
    z-index: 2;
    right: 10px;
  }
  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-around;
    background-color: $primary-blue;
    text-align: center;
    padding: 2rem;
    top: 10px;
    height: 8rem;
    width: 13rem;
    right: 20px;
    border-radius: 20px;
    border: 2px solid $primary-blue;
    position: absolute;
    background-color: white;
  }
}

.organize-link,
.logout-link {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  font-weight: bold;
  gap: 1rem;
  line-height: 3rem;
  font-size: 1rem;
  color: $primary-blue;
  svg {
    fill: $tertiary-blue;
    width: 2rem;
  }
  &:hover {
    color: $tertiary-blue-darker;
  }
}
