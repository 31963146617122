@use '../abstracts/' as *;

.private-homepage {
  background-color: $secondary-blue;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  &__logo-ctn {
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__logo {
    width: 17rem;
    @include sm {
      width: 20rem;
    }
    @include md {
      width: 25rem;
    }
    @include xl {
      width: 30rem;
    }
  }
  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    @include sm {
      width: 80%;
    }
    @include md {
      width: 70%;
    }
    @include lg {
      width: 60%;
    }
    @include xl {
      width: 50%;
    }
  }
  &__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $tertiary-blue;
    position: fixed;
    z-index: 1;
    overflow-y: auto;
    flex: 1;
    left: 50%;
    top: 50%;
    border-radius: 1rem;
    padding: 2rem 3rem;
    transform: translate(-50%, -50%);
    background-color: $secondary-blue;
    gap: 2rem;
    width: 80%;
    @include sm {
      width: 60%;
    }
    @include lg {
      width: 50%;
    }
    @include xl {
      width: 30%;
    }
    ul {
      color: $tertiary-blue;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
    }
    li {
      list-style-type: disc;
    }
    &__heading {
      text-align: center;
    }
    &__btn {
      cursor: pointer;
      position: absolute;
      bottom: 10rem;
      right: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      fill: $tertiary-blue;
      svg {
        width: 3rem;
      }
    }
  }
}
