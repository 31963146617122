@use '../abstracts/' as *;
.form {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__group {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  &__label {
    padding: 5px;
    color: $tertiary-blue;
  }
  &__input {
    background-color: white;
    height: 2rem;
    padding: 5px;
    border: 1px solid $tertiary-blue;
    border-radius: 0.5rem;
    font-size: 0.9rem;
  }
  &__error {
    color: red;
    font-size: 0.9rem;
  }
  @include sm {
    &__label {
      font-size: 1.2rem;
    }
    &__input {
      height: 2.5rem;
    }
    &__error {
      font-size: 1rem;
    }
  }
}
