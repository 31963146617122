@use '../abstracts/' as *;

.organize {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  margin-top: 2rem;
  text-align: center;
  padding: 2rem 1rem;
  @include md {
    flex-direction: row;
    padding: 0rem 1rem;
  }
  @include lg {
    gap: 4rem;
    padding: 0rem 3rem;
  }
  @include xl {
    padding: 0rem 5rem;
    gap: 10rem;
  }
  &__error {
    color: white;
    font-weight: bold;
    border-radius: 1rem;
    padding: 10px;
    align-self: center;
    width: 95%;
    background-color: red;
  }
  &__projects {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    padding: 1rem 0rem;
    border-radius: 10px;
    background-color: $primary-blue;
    h1 {
      color: white;
    }
    @include md {
      flex: 1;
      max-height: 47rem;
    }
    @include lg {
      flex-basis: 40%;
    }
  }
  &__file {
    gap: 1.5rem;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 10rem;
    @include md {
      flex-basis: 50%;
    }
    @include lg {
      flex-basis: 60%;
    }
  }
  &__heading {
    vertical-align: middle;
    @include md {
      flex: 1;
      padding-bottom: 5rem;
      align-self: center;
    }
    @include lg {
      flex-basis: 60%;
    }
  }
}
.projects-sidebar {
  border-top: 2px solid $tertiary-blue;
  display: flex;
  gap: 1rem;
  padding: 2rem;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  color: white;
  @include md {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
  }
  svg {
    width: 2.5rem;
    fill: white;
    pointer-events: none;
  }

  &__heading {
    color: white;
  }
  &__add-btn {
    align-self: center;
    padding: 10px;
    border-radius: 10px;
    svg {
      fill: white;
      width: 2rem;
    }
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: $tertiary-blue;
    &:hover {
      background-color: $tertiary-blue-darker;
    }
  }
}
.project {
  box-shadow: 0px 4px 6px -2px #000000;
  background-color: $tertiary-blue;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  gap: 1rem;
  @include md {
    width: 100%;
    justify-content: space-between;
    word-wrap: break-word;
    flex-direction: row;
    padding: 0;
  }
  svg {
    flex-shrink: 0;
  }

  &:hover {
    background-color: $tertiary-blue-darker;
  }
  &__btn-container {
    display: flex;
  }
  &__edit-btn {
    &:hover svg {
      fill: $bright-blue;
    }
  }
  &__delete-btn {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    &:hover svg {
      fill: $bright-blue;
    }
  }
  &__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    @include md {
      padding: 1rem;
      word-break: break-all;
    }
  }
  &__form {
    flex: 1;
  }
  &__input {
    background-color: white;
    min-width: 100%;
    color: black;
  }
  &--active {
    @extend .project;
    background-color: $secondary-blue;
    svg {
      fill: $tertiary-blue !important;
    }

    &:hover {
      color: $tertiary-blue;

      background-color: $secondary-blue;
    }
    color: $tertiary-blue;
  }
}

.folder,
.question-file {
  background-color: $tertiary-blue;
  box-shadow: 0px 4px 6px -2px #000000;
  border-radius: 10px;
  word-break: break-all;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }
  p {
    justify-self: flex-start;
  }
  &:hover {
    background-color: $tertiary-blue-darker;
  }

  &__form {
    width: 100%;
  }
  &__input {
    background-color: white;
    width: 100%;
    color: black;
  }
  &__btn-container {
    display: flex;
    justify-self: flex-end;
  }
  &__details {
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    @include md {
    }
  }
  &__edit-btn {
    &:hover svg {
      fill: $bright-blue;
    }
  }
  &__delete-btn {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    &:hover svg {
      fill: $bright-blue;
    }
  }
  &--active {
    @extend .folder;
    &:hover {
      color: $bright-blue;
    }
    color: $bright-blue;
  }
}
.new-folder-btn {
  flex: 1;
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  svg {
    width: 2rem;
  }
  &:hover svg {
    fill: $bright-blue;
  }
  fill: $tertiary-blue;
}

.file {
  &__details {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    @include md {
      padding: 0rem;
    }
    svg {
      width: 2rem;
    }
  }
  &__title {
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    svg {
      fill: $primary-blue;
      width: 3rem;
      flex-shrink: 0;
    }
  }
  &__back-btn {
    cursor: pointer;
    svg {
      fill: #124768;
      width: 2rem;
    }
    @include md {
      width: 3rem;
    }
  }
  &__delete {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      width: 2rem;
      fill: $tertiary-blue-darker;
    }
  }
  &__browser {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    max-height: 35rem;
    min-height: 35rem;
    flex-shrink: 0;
    width: 100%;
    background-color: $primary-blue;
    color: white;
    padding: 1rem;
    svg {
      width: 2.5rem;
      fill: white;
      pointer-events: none;
    }
    flex-direction: column;
    @include md {
      padding: 2rem 1rem;
    }
    @include lg {
      padding: 2rem 4rem;
    }
  }
  &__path {
    svg {
      width: 1.5rem;
      fill: $primary-blue;
    }
    align-items: center;
    color: $tertiary-blue-darker;
    padding: 10px 10px;
    border-top: 2px solid $tertiary-blue-darker;
    border-bottom: 2px solid $tertiary-blue-darker;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    gap: 10px;
    @include md {
      padding: 2px 10px;
    }
    &-item {
      display: flex;
      gap: 10px;
    }
  }
}

.question-info,
.answer-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  color: white;
  word-wrap: break-word;
  overflow-y: auto;
  @include md {
    padding: 1rem;
    max-height: 38rem;
    min-height: 38rem;
  }

  &__container {
    background-color: $tertiary-blue;
    border-radius: 1rem;
    padding: 2rem 2rem;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__btn {
    background-color: $primary-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    fill: white;
    align-self: flex-start;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    svg {
      width: 2rem;
      fill: white;
    }
    &:hover {
      background-color: $tertiary-blue-darker;
    }
  }
  &__title {
    color: white;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    sub {
      vertical-align: sub;
      font-size: smaller;
    }
    p {
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }
    a {
      cursor: pointer;
      color: rgb(0, 204, 255);
      text-decoration: underline;
      &:hover {
        color: rgb(0, 0, 0);
      }
    }
    ul,
    li {
      list-style: disc;
      padding: 0rem 1rem;
      margin: 1rem 0;
    }
  }
}
