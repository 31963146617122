@use '../abstracts/' as *;
html {
  width: 100%;
  height: 100%;
  scrollbar-width: 10px;
  scrollbar-color: $tertiary-blue-darker #3da2c0ad;
}

body {
  width: 100%;
}
strong {
  font-weight: bold;
}
pre code {
  background: none;
}
pre {
  max-height: 600px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 1rem;
  white-space: pre-wrap;
  margin: 1rem 0rem;
  border-radius: 10px;
  background: $tertiary-blue-darker;
  box-shadow: 0px 7px 12px 0px $tertiary-blue-darker;
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
    'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
    'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
    monospace;
}
blockquote {
  background: $tertiary-blue-darker;
  border-left: 10px solid $primary-blue;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
}
code {
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  padding: 2px;
  background-color: $tertiary-blue-darker;
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
    'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
    'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
    monospace;
}
blockquote p {
  display: inline;
}
table {
  border: 2px solid white;
  width: 100%;
}
tr {
  border-bottom: 1px solid #ccc;
}
th,
td {
  text-align: left;
  padding: 4px;
}
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

// Scrollbar for webkit browsers

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3da2c0ad;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $tertiary-blue-darker;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-blue;
}

button:focus,
input:focus {
  outline: $bright-blue 2px solid;
}
