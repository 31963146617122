@use '../abstracts/' as *;

.heading-primary {
  font-size: 1.4rem;
  color: $tertiary-blue;
  font-weight: bold;
  @include sm {
    font-size: 1.6rem;
  }
  @include lg {
    font-size: 1.8rem;
  }
}
.heading-secondary {
  font-size: 1.1rem;
  color: $tertiary-blue;
  padding: 0.5rem;
  font-weight: bold;
  @include sm {
    font-size: 1.3rem;
  }
  @include lg {
    font-size: 1.4rem;
  }
}

.not-found__heading {
  color: $primary-blue;
  font-weight: bold;
  font-size: 2rem;
}

.error {
  color: red;
}
