@use '../abstracts/' as *;
.question-details {
  position: relative;
  display: flex;
  flex: 1;
  margin-top: 2rem;
  flex-direction: column;
  text-align: center;
  width: 100%;
  @include sm {
    padding: 0 0;
  }
  @include md {
    padding: 0 2%;
  }
  @include lg {
    padding: 0 10%;
  }

  @include xl {
    padding: 0 20%;
  }
  &__owner {
    color: white;
    font-weight: bold;
    @include sm {
      align-self: flex-end;
    }
  }
  &__comment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0;
    border-radius: 1rem;
    padding: 1rem;
    background-color: $tertiary-blue-darker;
  }
  &__body,
  .answer__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-wrap: break-word;
    sub {
      vertical-align: sub;
      font-size: smaller;
    }
    p {
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }
    a {
      cursor: pointer;
      color: rgb(0, 204, 255);
      text-decoration: underline;
      &:hover {
        color: rgb(0, 0, 0);
      }
    }
    ul,
    li {
      list-style: disc;
      padding: 0rem 1rem;
      margin: 1rem 0;
    }
  }
  &__nav {
    margin: auto;
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    button {
      flex: 1;
    }
    @include sm {
      width: 80%;
    }
  }

  &__heading {
    flex-basis: 50%;
  }
  &__stats {
    display: flex;
    padding: 2rem;
    border-radius: 10px;
    background-color: $primary-blue;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include sm {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }
  &creation_date,
  &__edit-date {
    font-size: 1rem;
  }

  &__back-btn {
    cursor: pointer;
    svg {
      width: 2rem;
      @include sm {
        width: 3rem;
      }
    }
  }
}

.comment__owner {
  color: $bright-blue;
  font-weight: bold;
  border-radius: 10px;
  margin: 0 0.5rem;
}
.comment__date {
  color: rgb(73, 132, 180);
  font-weight: bold;
}

.stack-overflow-btn {
  display: flex;
  cursor: pointer;
  flex-basis: 25%;
  flex-direction: column;
  align-items: flex-end;
  color: $tertiary-blue;
  font-weight: bold;
  &:hover {
    color: $tertiary-blue-darker;
  }
  &__icon {
    fill: $tertiary-blue;
    width: 3rem;
    @include sm {
      width: 2rem;
    }
  }

  &__link {
    display: none;
    @include sm {
      display: block;
    }
  }
  svg {
    width: 2rem;
    @include sm {
      width: 3rem;
    }
  }
}

.save-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  svg {
    width: 2rem;
    fill: white;
  }
  align-self: center;
  background-color: $tertiary-blue-darker;
  border-radius: 10px;
  padding: 0 1rem;
  height: 3rem;
  width: 8rem;
  cursor: pointer;
  &:hover {
    background-color: $secondary-blue;
    color: $tertiary-blue-darker;
  }
  &:hover svg {
    fill: $tertiary-blue-darker;
  }
}

.comments-btn {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  &:hover &__icon {
    fill: $bright-blue;
  }
  &__icon-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    fill: white;
  }
  &__text {
    font-size: 1.3rem;
  }
}

.save-modal {
  position: fixed;
  z-index: 1;
  overflow-y: auto;
  flex: 1;
  left: 50%;
  top: 50%;
  border-radius: 1rem;
  transform: translate(-50%, -50%);
  border: 2px solid $tertiary-blue-darker;
  background: $secondary-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  padding: 1rem;
  h1 {
    padding: 1rem;
  }
  h2,
  label {
    align-self: flex-start;
  }
  @include sm {
    padding: 0 2rem;
    width: 80%;
  }
  @include md {
    width: 70%;
  }
  @include lg {
    padding: 0 4rem;
    width: 50%;
  }

  &__info {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: $tertiary-blue;
    fill: $tertiary-blue-darker;
  }
  &__close-btn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    align-self: flex-end;
    &:hover svg {
      fill: $tertiary-blue-darker;
    }
    svg {
      fill: $tertiary-blue;
      width: 2rem;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__input {
    flex: 1;
    text-align: start;
    color: white;
    border-radius: 5px;
    padding: 0 1rem;
    background-color: $tertiary-blue-darker;
    min-height: 2rem;
    max-height: 2rem;
    width: 100%;
    margin: 10px;
    @include sm {
      margin: 1rem;
    }
  }
  &__textarea {
    @extend .save-modal__input;
    padding: 1rem;
    word-wrap: break-word;
    min-height: 5rem;
  }
}

.backdrop {
  background-color: #266f9d9f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.file-container {
  overflow-x: auto;
  display: flex;
  gap: 1rem;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background-color: $tertiary-blue-darker;
  color: white;
  width: 100%;
  min-height: 6rem;
  svg {
    width: 2rem;
    fill: white;
    pointer-events: none;
    @include sm {
      width: 2.5rem;
    }
  }
  @include sm {
    max-height: 7rem;
    padding: 1rem;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $secondary-blue;
    }
    &:hover svg {
      fill: $secondary-blue;
    }
    &--active {
      color: $bright-blue;
    }
    &--active svg {
      fill: $bright-blue;
    }
    &__form {
      width: 80%;
    }
    &__input {
      background-color: $secondary-blue;
      width: 100%;
      color: black;
    }
  }
}

// Dual Ring spinner component
.KWzbj {
  align-self: center;
}
