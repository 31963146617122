@use '../abstracts/' as *;

.public-homepage {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  @include lg {
    flex-direction: row;
  }
}

.welcome-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__logo {
    padding-top: 2rem;
    width: 19rem;
    z-index: 1;
  }
  &__illustration {
    width: 20rem;
    margin-top: -3rem;
  }
  &__heading {
    font-size: 1.2rem;
    padding: 1rem;
    color: $tertiary-blue;
    text-align: center;
    font-weight: bold;
  }
  @include sm {
    &__logo {
      width: 25rem;
    }
    &__illustration {
      width: 27rem;
      margin-top: -4rem;
    }
  }
  @include lg {
    flex: 2;
    &__logo {
      padding-top: 0;
      width: 30rem;
    }
    &__illustration {
      width: 40rem;
      margin-top: -5rem;
    }
    &__heading {
      font-size: 2rem;
      padding: 4rem;
    }
  }
  @include xl {
    flex: 2.5;
    &__logo {
      width: 40rem;
    }
    &__illustration {
      width: 50rem;
      margin-top: -10rem;
    }
    &__heading {
      font-size: 2.5rem;
    }
  }
}

.auth-section {
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: $secondary-blue;
  &__text__span {
    background-color: red;
    margin-left: 0.5rem;
    color: $tertiary-blue;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: $tertiary-blue-darker;
    }
  }
  @include lg {
    justify-content: space-evenly;
  }
}

.auth-switch {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  &__text {
    color: $tertiary-blue;
  }
  &__btn {
    color: $tertiary-blue;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: $tertiary-blue-darker;
    }
  }
  @include xl {
    &__text {
      font-size: 1.2rem;
    }
  }
}
